import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  useLazyViewAllExportControlCommandsQuery,
  useLazyViewExportControlStatisticsQuery,
} from "../../redux/api/power-limit/powerLimitAPI";
import ControlCommandCard from "../../shared/components/control-command-card/control-command-card";
import SolarInfoStatisticCard from "../../shared/components/solar-info-statistic-card/solar-info-statistic-card";
import IViewExportControlCommandsResponseDTO from "../../shared/oversight-core/dtos/response-dtos/view-export-control-commands-response-dto";
import IViewExportControlStatisticsResponseDTO from "../../shared/oversight-core/dtos/response-dtos/view-export-control-statistics-response-dto";
import { AppRoute } from "../../shared/oversight-core/interfaces/app-routes";
import { IShallowExportControlCommandView } from "../../shared/oversight-core/interfaces/shallow-export-control-command-view";
import AppSelect, {
  Option,
} from "../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import Pagination from "../../shared/oversight-core/ui-elements/pagination/pagination";
import RealTimeAreaChart from "../../shared/oversight-core/ui-elements/real-time-area-chart/realtime-area-chart";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import Helper from "../../shared/oversight-core/utils/helpers";

const filterOptions: Option[] = [
  { value: "created_at", label: "Recently Added" },
  { value: "start_date", label: "Start Date" },
];

const PowerLimit = () => {
  const navigate = useNavigate();

  const [selectedFilterOption, setSelectedFilterOption] = useState<Option>({
    ...filterOptions[0],
  });
  const [filters, setFilters] = useState({ pageNumber: 0 });
  const [exportControlStatistics, setExportControlStatistics] =
    useState<IViewExportControlStatisticsResponseDTO>();
  const [totalElements, setTotalElements] = useState(0);
  const [controlCommands, setControlCommands] = useState<
    IShallowExportControlCommandView[]
  >([]);

  const [
    triggerExportControlStatistics,
    { isFetching: isFetchingExportControlStatistics },
  ] = useLazyViewExportControlStatisticsQuery();
  const [
    triggerAllExportControlCommands,
    { isFetching: isFetchingAllExportControlCommands },
  ] = useLazyViewAllExportControlCommandsQuery();

  useEffect(() => {
    triggerExportControlStatistics()
      .unwrap()
      .then((res: IViewExportControlStatisticsResponseDTO) => {
        setExportControlStatistics(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [triggerExportControlStatistics]);

  useEffect(() => {
    triggerAllExportControlCommands({
      pageNumber: filters.pageNumber,
      pageSize: 3,
      sortBy: selectedFilterOption.value,
    })
      .unwrap()
      .then((res: IViewExportControlCommandsResponseDTO) => {
        setControlCommands(res.genericPage.elements);
        setTotalElements(res.genericPage.totalElements);
      })
      .catch(() => {
        setTotalElements(0);
      });
  }, [triggerAllExportControlCommands, filters, selectedFilterOption]);

  const graphLabels =
    exportControlStatistics?.dailyGenerationPower.map((i) =>
      moment(i.date).format("HH:mm")
    ) || [];
  const graphData =
    exportControlStatistics?.dailyGenerationPower.map((i) => i.export / 1000) ||
    [];

  return (
    <>
      <Row className="gy-4">
        <Col className="col-6 col-xl-4 col-xxl-2">
          <SolarInfoStatisticCard
            title="Total Sites"
            value={exportControlStatistics?.numberOfPlants || 0}
            isLoading={isFetchingExportControlStatistics}
          />
        </Col>
        <Col className="col-6 col-xl-4 col-xxl-2">
          <SolarInfoStatisticCard
            title="Online Sites"
            value={exportControlStatistics?.numberOfOnlinePlants || 0}
            isLoading={isFetchingExportControlStatistics}
          />
        </Col>
        <Col className="col-6 col-xl-4 col-xxl-3">
          <SolarInfoStatisticCard
            title="Total Maximum Export Capacity"
            value={
              Number(
                Helper.roundTo2(
                  exportControlStatistics?.totalCapacity
                    ? exportControlStatistics.totalCapacity / 1000
                    : 0
                )
              ) || 0
            }
            isLoading={isFetchingExportControlStatistics}
            unit="kW"
          />
        </Col>
        <Col className="col-6 col-xl-4 col-xxl-3">
          <SolarInfoStatisticCard
            title="Current Power Generation"
            value={
              Number(
                Helper.roundTo2(
                  exportControlStatistics?.dailyGenerationPower &&
                    exportControlStatistics.dailyGenerationPower.length > 0
                    ? exportControlStatistics.dailyGenerationPower[
                        exportControlStatistics.dailyGenerationPower.length - 1
                      ].export / 1000
                    : 0
                )
              ) || 0
            }
            isLoading={isFetchingExportControlStatistics}
            unit="kW"
          />
        </Col>
        <Col className="col-6 col-xl-4 col-xxl-2">
          <SolarInfoStatisticCard
            title={`Export Limited ${
              exportControlStatistics?.numberOfLimitedPlants
                ? `(From ${exportControlStatistics?.numberOfLimitedPlants} Sites)`
                : ``
            }`}
            value={
              Number(
                Helper.roundTo2(
                  Number(exportControlStatistics?.limitedPower) / 1000
                )
              ) || 0
            }
            isLoading={isFetchingExportControlStatistics}
            unit="kW"
          />
        </Col>
      </Row>
      <div className="bg-white p-3 rounded-3 mt-4">
        <Row className="align-items-center justify-content-between">
          <Col className="text-dark font-size-14 font-weight-500">
            Export to grid
          </Col>
          {/* <Col className="col-auto">
            <ButtonWithBadge
              text="Filter"
              icon="filter_alt"
              onClick={() => {}}
            />
          </Col> */}
        </Row>
        <RealTimeAreaChart
          borderColor1="#0EB471"
          backgroundColor1="#29CC4D17"
          label1=""
          yAxesUnit="kW"
          labels={graphLabels}
          data1={graphData}
          isFetching={isFetchingExportControlStatistics}
        />
      </div>
      <Row className="align-items-center justify-content-between mt-4">
        <Col className="col-12 col-lg-auto col-sm-8">
          <Row className="align-items-center">
            <Col className="col-auto text-dark font-size-16 font-weight-500">
              Export Control Commands
            </Col>
            <Col>
              <AppButton
                text="Add New Command"
                className="font-size-12 px-4"
                onClick={() =>
                  navigate(AppRoute.ADMIN_POWER_LIMIT_ADD_NEW_COMMAND)
                }
              />
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-sm-4 mt-4 mt-sm-0">
          <AppSelect
            selectedValue={selectedFilterOption}
            options={filterOptions}
            onChangeOption={(selectedOption) => {
              setSelectedFilterOption(selectedOption);
            }}
            className="text-light font-size-12"
          />
        </Col>
      </Row>
      <div className="mt-4">
        {isFetchingAllExportControlCommands ? (
          <SpinnerModal
            show={isFetchingAllExportControlCommands}
            withOverlay={false}
          />
        ) : (
          <>
            {controlCommands && controlCommands.length > 0 ? (
              controlCommands.map((controlCommand) => {
                return (
                  <Row className="mt-4" key={controlCommand.id}>
                    <Col>
                      <ControlCommandCard
                        controlCommand={controlCommand}
                        onClick={() =>
                          navigate(
                            AppRoute.ADMIN_POWER_LIMIT_VIEW_CONTROL_COMMAND,
                            {
                              state: {
                                id: controlCommand.id,
                              },
                            }
                          )
                        }
                      />
                    </Col>
                  </Row>
                );
              })
            ) : (
              <div className="container-dash text-center text-light">
                No added export control commands
              </div>
            )}
          </>
        )}
      </div>
      <Row className="mt-4">
        <Col>
          <Pagination
            itemsPerPage={3}
            length={totalElements}
            currentPage={filters.pageNumber + 1}
            updateCurrentPage={(pn) => {
              setFilters((ps) => ({ ...ps, pageNumber: pn - 1 }));
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default PowerLimit;
