import IAddExportControlCommandRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/add-export-control-command-request-dto";
import ICalculateExportControlCommandRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/calculate-export-control-command-request-dto";
import IUpdateExportControlCommandRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/update-export-control-command-request-dto";
import ICalculateExportControlCommandResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/calculate-export-control-command-response-dto";
import IListAllPowerLimitPlantsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/list-all-power-limit-plants-response-dto";
import IViewExportControlCommandResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-export-control-command-response-dto";
import IViewExportControlCommandsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-export-control-commands-response-dto";
import IViewExportControlStatisticsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-export-control-statistics-response-dto";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const powerLimitAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    viewExportControlStatistics: builder.query<
      IViewExportControlStatisticsResponseDTO,
      void
    >({
      transformErrorResponse,
      query() {
        return {
          url: `admin/power-limit/export-control-command/statistics`,
          method: "GET",
        };
      },
    }),
    viewAllExportControlCommands: builder.query<
      IViewExportControlCommandsResponseDTO,
      {
        pageNumber?: number;
        pageSize?: number;
        sortBy?: string;
        ascending?: boolean;
      }
    >({
      transformErrorResponse,
      query({ pageNumber, pageSize, sortBy, ascending }) {
        return {
          url: `admin/power-limit/export-control-command/view`,
          method: "GET",
          params: {
            pageNumber,
            pageSize,
            sortBy,
            ascending,
          },
        };
      },
    }),
    getPaginatedPowerLimitSolarPlantList: builder.query<
      IListAllPowerLimitPlantsResponseDTO,
      {
        searchValue?: string;
        type?: string;
        district?: string;
        pageNumber?: number;
        pageSize?: number;
        sortBy?: string;
        ascending?: boolean;
      }
    >({
      transformErrorResponse,
      query({
        searchValue,
        type,
        district,
        pageNumber,
        pageSize,
        sortBy,
        ascending,
      }) {
        return {
          url: `admin/power-limit/solar-plant/list`,
          method: "GET",
          params: {
            searchValue,
            type,
            district,
            pageNumber,
            pageSize,
            sortBy,
            ascending,
          },
        };
      },
    }),
    addExportControlCommand: builder.mutation<
      { exportControlIdentity: string },
      IAddExportControlCommandRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: "admin/power-limit/export-control-command/add",
          method: "POST",
          body: data,
        };
      },
    }),
    updateExportControlCommand: builder.mutation<
      { exportControlCommandIdentity: string },
      IUpdateExportControlCommandRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: `admin/power-limit/export-control-command/${data.id}/update`,
          method: "PUT",
          body: data,
        };
      },
    }),
    viewExportControlCommand: builder.query<
      IViewExportControlCommandResponseDTO,
      { id: string }
    >({
      transformErrorResponse,
      query(id) {
        return {
          url: `admin/power-limit/export-control-command/${id}/view`,
          method: "GET",
        };
      },
    }),
    deleteExportControlCommand: builder.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `admin/power-limit/export-control-command/${id}/delete`,
          method: "DELETE",
        };
      },
    }),
    stopExportControlCommand: builder.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `admin/power-limit/export-control-command/${id}/stop`,
          method: "PUT",
        };
      },
    }),
    calculateExportControlCommand: builder.mutation<
      ICalculateExportControlCommandResponseDTO,
      ICalculateExportControlCommandRequestDTO
    >({
      transformErrorResponse,
      query(data) {
        return {
          url: "admin/power-limit/export-control-command/calculate",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyViewExportControlStatisticsQuery,
  useLazyViewAllExportControlCommandsQuery,
  useLazyGetPaginatedPowerLimitSolarPlantListQuery,
  useAddExportControlCommandMutation,
  useUpdateExportControlCommandMutation,
  useLazyViewExportControlCommandQuery,
  useDeleteExportControlCommandMutation,
  useStopExportControlCommandMutation,
  useCalculateExportControlCommandMutation,
} = powerLimitAPI;
