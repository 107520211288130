import styles from "../radio-button/radio-button.module.scss";

interface IProps {
  value: string;
  checked: boolean;
  onClick: () => void;
}

const MultipleSelectRadioButton = (props: IProps) => {
  const { value, checked, onClick } = props;

  return (
    <label className={styles[`radio-button`]}>
      <input type="radio" value={value} checked={checked} onClick={onClick} />
      <div className={styles[`radio-circle`]}></div>
    </label>
  );
};

export default MultipleSelectRadioButton;
