import { EPlantType } from "../enums/plant-type";

const getPlantType = (): {
  value: EPlantType;
  label: string;
}[] => {
  const plantType = [
    { value: EPlantType.ALL, label: "All" },
    {
      value: EPlantType.COMMERCIAL_ROOFTOP,
      label: "Commercial Rooftop ",
    },
    {
      value: EPlantType.BATTERY_STORAGE,
      label: "Battery Storage",
    },
    { value: EPlantType.GROUND_MOUNTED, label: "Ground Mounted" },
    {
      value: EPlantType.RESIDENTIAL,
      label: "Residential   ",
    },
  ];
  return plantType;
};

export default getPlantType;
