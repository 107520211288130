import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  usePowerLimitPlantsBlackoutMutation,
  usePowerLimitPlantsShutdownAllMutation,
} from "../../redux/api/blackout/blackoutAPI";
import {
  useLazyGetPaginatedPowerLimitSolarPlantListQuery,
  useLazyViewExportControlStatisticsQuery,
} from "../../redux/api/power-limit/powerLimitAPI";
import IListAllPowerLimitPlantsResponseDTO from "../../shared/oversight-core/dtos/response-dtos/list-all-power-limit-plants-response-dto";
import IViewExportControlStatisticsResponseDTO from "../../shared/oversight-core/dtos/response-dtos/view-export-control-statistics-response-dto";
import { IPowerLimitPlantView } from "../../shared/oversight-core/interfaces/power-limit-plant-view";
import AppSwitch from "../../shared/oversight-core/ui-elements/app-switch/app-switch";
import AppButton from "../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import DataTable from "../../shared/oversight-core/ui-elements/data-table/data-table";
import RealTimeAreaChart from "../../shared/oversight-core/ui-elements/real-time-area-chart/realtime-area-chart";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import Helper from "../../shared/oversight-core/utils/helpers";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../shared/oversight-core/utils/toast";
import SitesShutDownModal from "./sites-shut-down-modal/sites-shut-down-modal";
import SitesTurnOnModal from "./sites-turn-on-modal/sites-turn-on-modal";

const Blackout = () => {
  const [showShutDownModal, setShutDownModal] = useState(false);
  const [exportControlStatistics, setExportControlStatistics] =
    useState<IViewExportControlStatisticsResponseDTO>();
  const [powerLimitSolarPlantLists, setPowerLimitSolarPlantLists] = useState<
    IPowerLimitPlantView[]
  >([]);
  const [updateCurrentState, setUpdateCurrentState] = useState(false);
  const [showTurnOnModal, setShowTurnOnModal] = useState(false);

  const [
    triggerExportControlStatistics,
    { isFetching: isFetchingExportControlStatistics },
  ] = useLazyViewExportControlStatisticsQuery();
  const [
    triggerPowerLimitSolarPlantList,
    { isFetching: isFetchingPowerLimitSolarPlantList },
  ] = useLazyGetPaginatedPowerLimitSolarPlantListQuery();
  const [triggerPowerLimitPlantsBlackout] =
    usePowerLimitPlantsBlackoutMutation();
  const [
    triggerPowerLimitPlantsShutdownAll,
    { isLoading: isLoadingPowerLimitPlantsShutdownAll },
  ] = usePowerLimitPlantsShutdownAllMutation();

  useEffect(() => {
    triggerExportControlStatistics()
      .unwrap()
      .then((res: IViewExportControlStatisticsResponseDTO) => {
        setExportControlStatistics(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [triggerExportControlStatistics, updateCurrentState]);

  useEffect(() => {
    triggerPowerLimitSolarPlantList({
      pageNumber: 0,
      pageSize: 1000,
    })
      .unwrap()
      .then((res: IListAllPowerLimitPlantsResponseDTO) => {
        setPowerLimitSolarPlantLists(res.genericPage.elements);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [triggerPowerLimitSolarPlantList, updateCurrentState]);

  const powerLimitPlantsBlackout = (
    plantIds: string[],
    isSiteShutdown?: boolean
  ) => {
    triggerPowerLimitPlantsBlackout({
      plantIdentities: plantIds,
    })
      .unwrap()
      .then(() => {
        setUpdateCurrentState((ps) => !ps);
        showSuccessMessage(
          isSiteShutdown ? `Site is now online` : `Site shutdown successfully`
        );
      })
      .catch(() => {
        showErrorMessage(
          isSiteShutdown ? `Site is online` : `Site shutdown unsuccessfully`
        );
      });
  };

  const onSubmitPowerLimitPlantsShutdownAll = (isShutDown: boolean) => {
    triggerPowerLimitPlantsShutdownAll({
      isAllShutdown: isShutDown,
    })
      .unwrap()
      .then(() => {
        setUpdateCurrentState((ps) => !ps);
        showSuccessMessage(
          `All sites ${isShutDown ? `shutdown` : `turn on`} successfully`
        );
        setShutDownModal(false);
        setShowTurnOnModal(false);
      })
      .catch(() => {
        showErrorMessage(
          `All sites ${isShutDown ? `shutdown` : `turn on`} unsuccessfully`
        );
      });
  };

  const graphLabels =
    exportControlStatistics?.dailyGenerationPower.map((i) =>
      moment(i.date).format("HH:mm")
    ) || [];
  const graphData =
    exportControlStatistics?.dailyGenerationPower.map((i) => i.export / 1000) ||
    [];

  return (
    <>
      <Row className="align-items-center bg-white rounded-3 mx-0 px-2 py-3">
        <Col>
          <Row className="row-cols-auto align-items-center gap-0 gap-xl-4 gy-2">
            <Col>
              <div className="text-light font-size-12 font-weight-400">
                Online Sites
              </div>
              <div className="text-dark font-size-14 font-weight-600">
                {exportControlStatistics?.numberOfOnlinePlants}
              </div>
            </Col>
            <Col>
              <div className="text-light font-size-12 font-weight-400">
                Power Generation
              </div>
              <div className="text-dark font-size-14 font-weight-600">
                {Number(
                  Helper.roundTo2(
                    exportControlStatistics?.dailyGenerationPower &&
                      exportControlStatistics.dailyGenerationPower.length > 0
                      ? exportControlStatistics.dailyGenerationPower[
                          exportControlStatistics.dailyGenerationPower.length -
                            1
                        ].export / 1000
                      : 0
                  )
                )}
                &nbsp; kW
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="col-auto">
          <Row className="row-cols-auto gx-3">
            <Col>
              <AppButton
                text="Turn On All"
                className="font-size-12 px-4"
                iconName="power_settings_new"
                onClick={() => setShowTurnOnModal(true)}
                disabled={powerLimitSolarPlantLists.every(
                  (powerLimitSolarPlant) => !powerLimitSolarPlant.shutdown
                )}
              />
            </Col>
            <Col>
              <AppButton
                text="Shut Down"
                className="font-size-12 px-4"
                variant="red"
                iconName="power_settings_new"
                onClick={() => setShutDownModal(true)}
                disabled={powerLimitSolarPlantLists.every(
                  (powerLimitSolarPlant) => powerLimitSolarPlant.shutdown
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="bg-white p-3 rounded-3 mt-4">
        <Row className="align-items-center justify-content-between">
          <Col className="text-dark font-size-14 font-weight-500">
            Export to grid
          </Col>
          {/* <Col className="col-auto">
            <ButtonWithBadge
              text="Filter"
              icon="filter_alt"
              onClick={() => {}}
            />
          </Col> */}
        </Row>
        <RealTimeAreaChart
          borderColor1="#0EB471"
          backgroundColor1="#29CC4D17"
          label1=""
          yAxesUnit="kW"
          labels={graphLabels}
          data1={graphData}
          isFetching={isFetchingExportControlStatistics}
        />
      </div>
      <Row className="align-items-center justify-content-between mt-4">
        <Col className="text-dark font-size-16 font-weight-500">
          Sites Affected
        </Col>
      </Row>
      <Row className="flex-column bg-white rounded-3 mt-4 mx-0">
        <Col>
          {isFetchingPowerLimitSolarPlantList ? (
            <div className="mt-3">
              <SpinnerModal
                show={isFetchingPowerLimitSolarPlantList}
                withOverlay={false}
              />
            </div>
          ) : (
            <DataTable
              headers={[
                { id: 1, columnName: "Site", isSort: true, disabled: true },
                {
                  id: 2,
                  columnName: "Current Export",
                  isSort: true,
                  disabled: true,
                },
                { id: 3, columnName: "Off/On" },
              ]}
              data={powerLimitSolarPlantLists.map(
                (powerLimitSolarPlantList) => {
                  return {
                    data: [
                      <>{powerLimitSolarPlantList.plantName}</>,
                      <>
                        {powerLimitSolarPlantList.plantCurrentExport / 1000} kW
                      </>,
                      <>
                        <AppSwitch
                          isOn={!powerLimitSolarPlantList.shutdown}
                          size="sm"
                          onSwitch={() =>
                            powerLimitPlantsBlackout(
                              [powerLimitSolarPlantList.id],
                              powerLimitSolarPlantList.shutdown
                            )
                          }
                        />
                      </>,
                    ],
                  };
                }
              )}
            />
          )}
        </Col>
      </Row>
      <SitesShutDownModal
        show={showShutDownModal}
        onCancel={() => setShutDownModal(false)}
        onClose={() => setShutDownModal(false)}
        onConfirm={() => onSubmitPowerLimitPlantsShutdownAll(true)}
        isLoading={isLoadingPowerLimitPlantsShutdownAll}
      />
      <SitesTurnOnModal
        show={showTurnOnModal}
        onCancel={() => setShowTurnOnModal(false)}
        onClose={() => setShowTurnOnModal(false)}
        onConfirm={() => onSubmitPowerLimitPlantsShutdownAll(false)}
        isLoading={isLoadingPowerLimitPlantsShutdownAll}
      />
    </>
  );
};

export default Blackout;
