import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddExportControlCommandMutation,
  useCalculateExportControlCommandMutation,
  useLazyGetPaginatedPowerLimitSolarPlantListQuery,
  useLazyViewExportControlStatisticsQuery,
  useUpdateExportControlCommandMutation,
} from "../../../redux/api/power-limit/powerLimitAPI";
import SolarInfoStatisticCard from "../../../shared/components/solar-info-statistic-card/solar-info-statistic-card";
import ICalculateExportControlCommandResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/calculate-export-control-command-response-dto";
import IListAllPowerLimitPlantsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/list-all-power-limit-plants-response-dto";
import IViewExportControlStatisticsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-export-control-statistics-response-dto";
import { OvstErrorCode } from "../../../shared/oversight-core/enums/ovst-error-codes";
import { EPlantType } from "../../../shared/oversight-core/enums/plant-type";
import { EPowerLimitType } from "../../../shared/oversight-core/enums/power-limit-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { IHttpError } from "../../../shared/oversight-core/interfaces/http-errror";
import { IPlantStatus } from "../../../shared/oversight-core/interfaces/plant-status";
import { IPowerLimitPlantView } from "../../../shared/oversight-core/interfaces/power-limit-plant-view";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import ArrowButton from "../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import DataTable from "../../../shared/oversight-core/ui-elements/data-table/data-table";
import AppInput from "../../../shared/oversight-core/ui-elements/input/app-input";
import MultipleSelectRadioButton from "../../../shared/oversight-core/ui-elements/multiple-select-radio-button/multiple-select-radio-button";
import Search from "../../../shared/oversight-core/ui-elements/search/search";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import { formatDate } from "../../../shared/oversight-core/utils/date-utils";
import getDistrict from "../../../shared/oversight-core/utils/get-district";
import getPlantType from "../../../shared/oversight-core/utils/get-plant-type";
import Helper from "../../../shared/oversight-core/utils/helpers";
import {
  convertToMinutes,
  getHoursAndMinutesSeparately,
  mergeDateAndTime,
} from "../../../shared/oversight-core/utils/time-utils";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../shared/oversight-core/utils/toast";

const powerLimitTypeOptions: Option[] = [
  {
    value: EPowerLimitType.MAXIMUM_EXPORT,
    label: "Maximum Export (kW)",
  },
  {
    value: EPowerLimitType.RELATIVE_MAXIMUM_EXPORT,
    label: "Relative Maximum Export (kW)",
  },
  {
    value: EPowerLimitType.POWER_CURTAIL,
    label: "Curtailment (kW)",
  },
  {
    value: EPowerLimitType.EXPORT_PERCENTAGE,
    label: "Limit Export to (%)",
  },
];

interface IFormInput {
  value: number | undefined;
  rampingRateInHours: number | undefined;
  rampingRateInMinutes: number | undefined;
  exportControlStartTime: string;
  exportControlDurationInHours: number | undefined;
  exportControlDurationInMinutes: number | undefined;
  plantIdentities: string[];
}

const defaultFormValues: IFormInput = {
  value: undefined,
  rampingRateInHours: undefined,
  rampingRateInMinutes: undefined,
  exportControlStartTime: "",
  exportControlDurationInHours: undefined,
  exportControlDurationInMinutes: undefined,
  plantIdentities: [],
};

const AddUpdateNewCommand = () => {
  const { state } = useLocation();

  const [filters, setFilters] = useState({ searchText: "" });
  const [selectedDistrict, setSelectedDistrict] = useState<Option>({
    value: "",
    label: "Select District",
  });
  const [selectedPlantType, setSelectedPlantType] = useState<Option>({
    ...getPlantType()[0],
  });
  const [selectedPowerLimitOption, setSelectedPowerLimitOption] =
    useState<Option>({
      ...powerLimitTypeOptions[0],
    });
  const [powerLimitSolarPlantLists, setPowerLimitSolarPlantLists] = useState<
    IPowerLimitPlantView[]
  >([]);
  const [selectedPlantIdentities, setSelectedPlantIdentities] = useState<
    string[]
  >([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [
    calculateExportControlCommandData,
    setCalculateExportControlCommandData,
  ] = useState<ICalculateExportControlCommandResponseDTO>();
  const [exportControlStatistics, setExportControlStatistics] =
    useState<IViewExportControlStatisticsResponseDTO>();
  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  const [
    triggerPowerLimitSolarPlantList,
    { isFetching: isFetchingPowerLimitSolarPlantList },
  ] = useLazyGetPaginatedPowerLimitSolarPlantListQuery();

  const [
    triggerExportControlStatistics,
    { isFetching: isFetchingExportControlStatistics },
  ] = useLazyViewExportControlStatisticsQuery();
  const [
    addExportControlCommand,
    { isLoading: isLoadingAddingExportControlCommand },
  ] = useAddExportControlCommandMutation();
  const [
    updateExportControlCommand,
    { isLoading: isLoadingUpdatingExportControlCommand },
  ] = useUpdateExportControlCommandMutation();
  const [
    calculateExportControlCommand,
    { isLoading: isLoadingCalculateExportControlCommand },
  ] = useCalculateExportControlCommandMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const currentValue = Number(watch("value"));

  useEffect(() => {
    const exportControlCommand = state?.exportControlCommand;

    if (exportControlCommand) {
      const exportControlCommandStartDate = new Date(
        exportControlCommand.exportControlCommandStartDate
      );

      setValue(
        "value",
        selectedPowerLimitOption.value === EPowerLimitType.EXPORT_PERCENTAGE
          ? exportControlCommand.value
          : Number(exportControlCommand.value / 1000)
      );
      setValue(
        "rampingRateInHours",
        getHoursAndMinutesSeparately(exportControlCommand.rampingRateInMinutes)
          .hours
      );
      setValue(
        "rampingRateInMinutes",
        getHoursAndMinutesSeparately(exportControlCommand.rampingRateInMinutes)
          .remainingMinutes
      );
      setValue(
        "exportControlStartTime",
        `${exportControlCommandStartDate.getHours()}:${exportControlCommandStartDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}`
      );
      setValue(
        "exportControlDurationInHours",
        getHoursAndMinutesSeparately(exportControlCommand.duration).hours
      );
      setValue(
        "exportControlDurationInMinutes",
        getHoursAndMinutesSeparately(exportControlCommand.duration)
          .remainingMinutes
      );
      setSelectedDate(exportControlCommandStartDate);
      setSelectedPowerLimitOption(
        powerLimitTypeOptions.find(
          (find) => find.value === exportControlCommand.powerLimitType
        ) || powerLimitTypeOptions[0]
      );
      setSelectedPlantIdentities(
        exportControlCommand.plantStatuses.map(
          (plantStatus: IPlantStatus) => plantStatus.identity
        )
      );
    }
  }, [state?.exportControlCommand, setValue, selectedPowerLimitOption]);

  useEffect(() => {
    triggerPowerLimitSolarPlantList({
      searchValue: filters.searchText,
      pageNumber: 0,
      pageSize: 1000,
      district: selectedDistrict.value,
      type:
        selectedPlantType.value === EPlantType.ALL
          ? ``
          : selectedPlantType.value,
    })
      .unwrap()
      .then((res: IListAllPowerLimitPlantsResponseDTO) => {
        setPowerLimitSolarPlantLists(res.genericPage.elements);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    triggerPowerLimitSolarPlantList,
    selectedDistrict,
    selectedPlantType,
    filters,
  ]);

  useEffect(() => {
    triggerExportControlStatistics()
      .unwrap()
      .then((res: IViewExportControlStatisticsResponseDTO) => {
        setExportControlStatistics(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [triggerExportControlStatistics]);

  const onSubmit = (data: IFormInput) => {
    if (selectedPlantIdentities.length === 0) {
      showErrorMessage("Select at least one site");
      return;
    }

    const rampingRateInMinutes = convertToMinutes(
      Number(data.rampingRateInHours),
      Number(data.rampingRateInMinutes)
    );

    const exportControlDurationInMinutes = convertToMinutes(
      Number(data.exportControlDurationInHours),
      Number(data.exportControlDurationInMinutes)
    );

    const exportControlDate = formatDate(
      mergeDateAndTime(selectedDate, data.exportControlStartTime)
    );

    const value =
      selectedPowerLimitOption.value === EPowerLimitType.EXPORT_PERCENTAGE
        ? Number(data.value)
        : Number(data.value) * 1000;

    if (state?.exportControlCommand.id) {
      updateExportControlCommand({
        id: state?.exportControlCommand.id,
        powerLimitType: selectedPowerLimitOption.value as EPowerLimitType,
        value: value,
        rampingRateInMinutes,
        exportControlDate,
        exportControlDurationInMinutes,
        plantIdentities: selectedPlantIdentities,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("Export control command updated successfully");
          navigate(AppRoute.ADMIN_POWER_LIMIT);
        })
        .catch((error: IHttpError) => {
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0001) {
            showErrorMessage("Invalid ramping time and duration.");
            return;
          }
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0012) {
            showErrorMessage(
              "An export control command exits in the given range."
            );
            return;
          }
          showErrorMessage("Export control command updated unsuccessfully");
        });
    } else {
      addExportControlCommand({
        powerLimitType: selectedPowerLimitOption.value as EPowerLimitType,
        value: value,
        rampingRateInMinutes,
        exportControlDate,
        exportControlDurationInMinutes,
        plantIdentities: selectedPlantIdentities,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("Export control command added successfully");
          navigate(AppRoute.ADMIN_POWER_LIMIT);
        })
        .catch((error: IHttpError) => {
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0001) {
            showErrorMessage("Invalid ramping time and duration.");
            return;
          }
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0012) {
            showErrorMessage(
              "An export control command exits in the given range."
            );
            return;
          }
          showErrorMessage("Export control command added unsuccessfully");
        });
    }
  };

  useEffect(() => {
    if (selectedPlantIdentities) {
      const value =
        selectedPowerLimitOption.value === EPowerLimitType.EXPORT_PERCENTAGE
          ? Number(currentValue)
          : Number(currentValue) * 1000;

      calculateExportControlCommand({
        powerLimitType: selectedPowerLimitOption.value as EPowerLimitType,
        value: value,
        plantIdentities: selectedPlantIdentities,
      })
        .unwrap()
        .then((res: ICalculateExportControlCommandResponseDTO) => {
          setCalculateExportControlCommandData(res);
        })
        .catch(() => {
          showErrorMessage("Export control command calculation unsuccessfully");
        });
    }
  }, [
    selectedPowerLimitOption,
    currentValue,
    selectedPlantIdentities,
    calculateExportControlCommand,
  ]);

  return (
    <>
      <Row className="mb-2 mx-0">
        <Col>
          <ArrowButton onBack={() => navigate(-1)} />
        </Col>
      </Row>
      <Row className="gy-4">
        <Col className="col-6 col-xl-4 col-xxl-2">
          <SolarInfoStatisticCard
            title="Total Sites"
            value={exportControlStatistics?.numberOfPlants || 0}
            isLoading={isFetchingExportControlStatistics}
          />
        </Col>
        <Col className="col-6 col-xl-4 col-xxl-2">
          <SolarInfoStatisticCard
            title="Online Sites"
            value={exportControlStatistics?.numberOfOnlinePlants || 0}
            isLoading={isFetchingExportControlStatistics}
          />
        </Col>
        <Col className="col-6 col-xl-4 col-xxl-3">
          <SolarInfoStatisticCard
            title="Total Maximum Export Capacity"
            value={
              Number(
                Helper.roundTo2(
                  exportControlStatistics?.totalCapacity
                    ? exportControlStatistics.totalCapacity / 1000
                    : 0
                )
              ) || 0
            }
            isLoading={isFetchingExportControlStatistics}
            unit="kW"
          />
        </Col>
        <Col className="col-6 col-xl-4 col-xxl-3">
          <SolarInfoStatisticCard
            title="Current Power Generation"
            value={
              Number(
                Helper.roundTo2(
                  exportControlStatistics?.dailyGenerationPower &&
                    exportControlStatistics.dailyGenerationPower.length > 0
                    ? exportControlStatistics.dailyGenerationPower[
                        exportControlStatistics.dailyGenerationPower.length - 1
                      ].export / 1000
                    : 0
                )
              ) || 0
            }
            isLoading={isFetchingExportControlStatistics}
            unit="kW"
          />
        </Col>
        <Col className="col-6 col-xl-4 col-xxl-2">
          <SolarInfoStatisticCard
            title={`Export Limited ${
              exportControlStatistics?.numberOfLimitedPlants
                ? `(From ${exportControlStatistics?.numberOfLimitedPlants} Sites)`
                : ``
            }`}
            value={
              Number(
                Helper.roundTo2(
                  Number(exportControlStatistics?.limitedPower) / 1000
                )
              ) || 0
            }
            isLoading={isFetchingExportControlStatistics}
            unit="kW"
          />
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <Row className="bg-white rounded-3 mx-0 px-2 py-3">
          <Row>
            <Col className="text-dark font-size-16 font-weight-500">
              Command Settings
            </Col>
          </Row>
          <Row className="align-items-end gy-3 mt-2">
            <Col className="col-12 col-lg-6 col-sm-8 col-xxl-4">
              <Row>
                <div className="text-dark font-size-14 mb-1">Limitation</div>
                <Col className="pe-0">
                  <AppSelect
                    selectedValue={selectedPowerLimitOption}
                    options={powerLimitTypeOptions}
                    onChangeOption={(selectedOption) => {
                      setSelectedPowerLimitOption(selectedOption);
                    }}
                    borderRadiusStyle={"Right"}
                    className="font-size-12"
                    labelFontSize="font-size-14"
                  />
                </Col>
                <Col className="col-4 ps-0">
                  <AppInput
                    name="value"
                    placeholder=""
                    register={register("value", {
                      required: true,
                    })}
                    errors={Error}
                    isError={errors.value ? true : false}
                    borderRadiusStyle="Left"
                    type="number"
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-6 col-lg-3 col-sm-4 col-xxl-2">
              <Row>
                <div className="text-dark font-size-14 mb-1">Ramping Rate</div>
                <Col className="pe-0">
                  <AppInput
                    name="rampingRateInHours"
                    placeholder="HH"
                    register={register("rampingRateInHours", {
                      required: true,
                    })}
                    errors={Error}
                    isError={errors.rampingRateInHours ? true : false}
                    borderRadiusStyle="Right"
                    isRequired={false}
                    type="number"
                    labelFontSize="font-size-14"
                  />
                </Col>
                <Col className="ps-0">
                  <AppInput
                    name="rampingRateInMinutes"
                    placeholder="mm"
                    register={register("rampingRateInMinutes", {
                      required: true,
                    })}
                    errors={Error}
                    isError={errors.rampingRateInMinutes ? true : false}
                    borderRadiusStyle="Left"
                    type="number"
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-6 col-lg-3 col-sm-4 col-xxl-2">
              <div className="text-dark font-size-14 mb-1">Date</div>
              <AppDatePicker
                selectedDate={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                isInput
                inputDateFormat="dd/MM/yyyy"
                minDate={new Date()}
              />
            </Col>
            <Col className="col-6 col-lg-3 col-sm-4 col-xxl-2">
              <div className="text-dark font-size-14 mb-1">Start Time</div>
              <AppInput
                name="exportControlStartTime"
                placeholder="HH"
                register={register("exportControlStartTime", {
                  required: true,
                })}
                errors={Error}
                isError={errors.exportControlStartTime ? true : false}
                type="time"
                labelFontSize="font-size-14"
              />
            </Col>
            <Col className="col-6 col-lg-3 col-sm-4 col-xxl-2">
              <Row>
                <div className="text-dark font-size-14 mb-1">Duration</div>
                <Col className="pe-0">
                  <AppInput
                    name="exportControlDurationInHours"
                    placeholder="HH"
                    register={register("exportControlDurationInHours", {
                      required: true,
                    })}
                    errors={Error}
                    isError={errors.exportControlDurationInHours ? true : false}
                    borderRadiusStyle="Right"
                    isRequired={false}
                    type="number"
                    labelFontSize="font-size-14"
                  />
                </Col>
                <Col className="ps-0">
                  <AppInput
                    name="exportControlDurationInMinutes"
                    placeholder="mm"
                    register={register("exportControlDurationInMinutes", {
                      required: true,
                    })}
                    errors={Error}
                    isError={
                      errors.exportControlDurationInMinutes ? true : false
                    }
                    borderRadiusStyle="Left"
                    type="number"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
        <div className="bg-white p-4 rounded-3 mt-4">
          <Row className="gy-2">
            <Col className="col-auto">
              <div className="text-light font-size-12 font-weight-400">
                Sites
              </div>
              <div className="text-dark font-size-14 font-weight-600">
                {
                  powerLimitSolarPlantLists.filter(
                    (filterPlant) => !filterPlant.shutdown
                  ).length
                }
              </div>
            </Col>
            <Col className="col-auto">
              <div className="text-light font-size-12 font-weight-400">
                Selected Sites
              </div>
              <div className="text-dark font-size-14 font-weight-600">
                {selectedPlantIdentities.length}
              </div>
            </Col>
            <Col className="col-auto">
              <div className="text-light font-size-12 font-weight-400">
                Selected Sites Capacity
              </div>
              <div className="text-dark font-size-14 font-weight-600">
                {isLoadingCalculateExportControlCommand ? (
                  <SpinnerModal
                    show={isLoadingCalculateExportControlCommand}
                    withOverlay={false}
                  />
                ) : (
                  <>
                    {calculateExportControlCommandData?.totalCapacity
                      ? Helper.roundTo2(
                          Number(
                            calculateExportControlCommandData?.totalCapacity
                          ) / 1000
                        )
                      : 0}
                    &nbsp; kW
                  </>
                )}
              </div>
            </Col>
            <Col className="col-auto">
              <div className="text-light font-size-12 font-weight-400">
                Current Export
              </div>
              <div className="text-dark font-size-14 font-weight-600">
                {isLoadingCalculateExportControlCommand ? (
                  <SpinnerModal
                    show={isLoadingCalculateExportControlCommand}
                    withOverlay={false}
                  />
                ) : (
                  <>
                    {calculateExportControlCommandData?.currentExport
                      ? Helper.roundTo2(
                          Number(
                            calculateExportControlCommandData?.currentExport
                          ) / 1000
                        )
                      : 0}
                    &nbsp; kW
                  </>
                )}
              </div>
            </Col>
            <Col className="col-auto">
              <div className="text-light font-size-12 font-weight-400">
                Expected Reduction
              </div>
              <div className="text-dark font-size-14 font-weight-600">
                {isLoadingCalculateExportControlCommand ? (
                  <SpinnerModal
                    show={isLoadingCalculateExportControlCommand}
                    withOverlay={false}
                  />
                ) : (
                  <>
                    {calculateExportControlCommandData?.expectedReduction
                      ? Number(
                          Helper.roundTo2(
                            calculateExportControlCommandData?.expectedReduction /
                              1000
                          )
                        )
                      : 0}
                    &nbsp; kW
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-between mt-4">
            <Col className="col-12 col-lg-5">
              <Row className="align-items-center">
                <Col className="col-auto text-dark font-size-16 font-weight-500 pe-0">
                  Select Sites
                </Col>
                <Col className="col-8 col-lg-7 col-xxl-6 ps-2">
                  <Search
                    placeholder="Search Site"
                    onSearch={() => setFilters({ searchText: searchInput })}
                    handleSearchChange={(input) => setSearchInput(input)}
                    value={searchInput}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-12 col-lg-7 mt-3 mt-lg-0">
              <Row className="align-items-center gx-3">
                <Col className="col-6 col-lg-5 col-sm-4">
                  <AppSelect
                    selectedValue={selectedDistrict}
                    options={[{ value: "", label: "All" }, ...getDistrict()]}
                    onChangeOption={(selectedOption) => {
                      setSelectedDistrict(selectedOption);
                    }}
                    className="text-light font-size-12"
                  />
                </Col>
                <Col className="col-6 col-sm-5">
                  <AppSelect
                    selectedValue={selectedPlantType}
                    options={getPlantType()}
                    onChangeOption={(selectedOption) => {
                      setSelectedPlantType(selectedOption);
                    }}
                    className="text-light font-size-12"
                  />
                </Col>
                <Col
                  className="col-12 col-lg-2 col-sm-3 text-center text-primary text-sm-start cursor-pointer font-size-12 font-weight-500 mt-3 mt-sm-0"
                  onClick={() => {
                    if (
                      selectedPlantIdentities.length ===
                      powerLimitSolarPlantLists.filter(
                        (filterPlant) => !filterPlant.shutdown
                      ).length
                    ) {
                      setSelectedPlantIdentities([]);
                    } else {
                      setSelectedPlantIdentities(
                        powerLimitSolarPlantLists
                          .filter((filterPlant) => !filterPlant.shutdown)
                          .map(
                            (powerLimitSolarPlant) => powerLimitSolarPlant.id
                          )
                      );
                    }
                  }}
                >
                  {selectedPlantIdentities.length ===
                  powerLimitSolarPlantLists.filter(
                    (filterPlant) => !filterPlant.shutdown
                  ).length
                    ? `Unselect`
                    : `Select`}{" "}
                  All
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              {isFetchingPowerLimitSolarPlantList ? (
                <div className="mt-3">
                  <SpinnerModal
                    show={isFetchingPowerLimitSolarPlantList}
                    withOverlay={false}
                  />
                </div>
              ) : (
                <>
                  {powerLimitSolarPlantLists.filter(
                    (filterPlant) => !filterPlant.shutdown
                  ).length > 0 ? (
                    <DataTable
                      headers={[
                        { id: 1, columnName: "" },
                        { id: 2, columnName: "Site" },
                        { id: 3, columnName: "Current Export" },
                      ]}
                      data={powerLimitSolarPlantLists
                        .filter((filterPlant) => !filterPlant.shutdown)
                        .map((powerLimitSolarPlantList) => {
                          return {
                            data: [
                              <>
                                <MultipleSelectRadioButton
                                  value={powerLimitSolarPlantList.id}
                                  checked={selectedPlantIdentities.includes(
                                    powerLimitSolarPlantList.id
                                  )}
                                  onClick={() => {
                                    setSelectedPlantIdentities((prev) =>
                                      prev.includes(powerLimitSolarPlantList.id)
                                        ? prev.filter(
                                            (id) =>
                                              id !== powerLimitSolarPlantList.id
                                          )
                                        : [...prev, powerLimitSolarPlantList.id]
                                    );
                                  }}
                                />
                              </>,
                              <>{powerLimitSolarPlantList.plantName}</>,
                              <>
                                {powerLimitSolarPlantList.plantCurrentExport /
                                  1000}{" "}
                                kW
                              </>,
                            ],
                          };
                        })}
                    />
                  ) : (
                    <div className="container-dash p-3 text-center text-light font-size-14 font-weight-400 mt-4">
                      There are no sites available to display
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row className="align-items-center justify-content-end mt-4">
            <Col className="col-auto">
              <Row>
                <Col className="col-auto pe-0">
                  <AppButton
                    text={state?.exportControlCommand ? "Save" : "Confirm"}
                    type="submit"
                    className="font-size-12 px-4"
                    isLoading={
                      isLoadingAddingExportControlCommand ||
                      isLoadingUpdatingExportControlCommand
                    }
                  />
                </Col>
                <Col className="ps-0">
                  <AppButton
                    text="Cancel"
                    className="font-size-12"
                    variant="transparent"
                    onClick={() => navigate(AppRoute.ADMIN_POWER_LIMIT)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </form>
    </>
  );
};

export default AddUpdateNewCommand;
