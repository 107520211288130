import { EExportControlCommandStatus } from "../enums/export-control-command-status ";
import MaterialIcon from "../ui-elements/material-icon/material-icon";

export const getControlCommandStatus = (
  status: EExportControlCommandStatus
) => {
  switch (status) {
    case EExportControlCommandStatus.IN_PROGRESS:
      return (
        <div className="d-flex align-items-center gap-1">
          <MaterialIcon icon="play_arrow" color="#29CC39" />
          <div
            className="text-green-4 font-size-12 font-weight-500"
            style={{ letterSpacing: "2px" }}
          >
            Running
          </div>
        </div>
      );
    case EExportControlCommandStatus.GENERATION_COMPLETED:
      return (
        <div className="d-flex align-items-center gap-1">
          <MaterialIcon icon="skip_next" color="#3B86C2" />
          <div
            className="text-blue-4 font-size-12 font-weight-500"
            style={{ letterSpacing: "2px" }}
          >
            Upcoming
          </div>
        </div>
      );
    case EExportControlCommandStatus.COMPLETED:
      return (
        <div className="d-flex align-items-center gap-1">
          <MaterialIcon icon="stop" color="#69768B" />
          <div
            className="text-light font-size-12 font-weight-500"
            style={{ letterSpacing: "2px" }}
          >
            Completed
          </div>
        </div>
      );
    case EExportControlCommandStatus.FAILED:
      return (
        <div className="d-flex align-items-center gap-1">
          <MaterialIcon icon="question_mark" color="#d84560" />
          <div
            className="text-red font-size-12 font-weight-500"
            style={{ letterSpacing: "2px" }}
          >
            Failed
          </div>
        </div>
      );
    case EExportControlCommandStatus.GENERATION_FAILED:
      return (
        <div className="d-flex align-items-center gap-1">
          <MaterialIcon icon="question_mark" color="#d84560" />
          <div
            className="text-red font-size-12 font-weight-500"
            style={{ letterSpacing: "2px" }}
          >
            Failed
          </div>
        </div>
      );
    case EExportControlCommandStatus.STOPPED:
      return (
        <div className="d-flex align-items-center gap-1">
          <MaterialIcon icon="stop_circle" color="#d84560" />
          <div
            className="text-red font-size-12 font-weight-500"
            style={{ letterSpacing: "2px" }}
          >
            Stopped
          </div>
        </div>
      );
    default:
      return "-";
  }
};
