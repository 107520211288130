import ModalContainer, {
  ModelContainerProps,
} from "../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
}

const SitesShutDownModal = (props: IProps) => {
  const { show, ...rest } = props;

  return (
    <ModalContainer
      {...rest}
      title="Shut Down All Sites?"
      show={show}
      size="modal-md"
      confirmButtonText="Shut Down"
      confirmButtonVariant="red"
      cancelButtonText="Cancel"
    >
      <>
        <p className="text-light font-size-14 font-weight-400">
          Are you sure <span className="font-weight-700">all site</span>
          &nbsp;exports to grid will be stopped by shutting down all sites ?
        </p>
      </>
    </ModalContainer>
  );
};

export default SitesShutDownModal;
