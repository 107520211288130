import moment from "moment";
import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { EExportControlCommandStatus } from "../../oversight-core/enums/export-control-command-status ";
import {
  EPowerLimitType,
  powerLimitTypeText,
} from "../../oversight-core/enums/power-limit-type";
import { IShallowExportControlCommandView } from "../../oversight-core/interfaces/shallow-export-control-command-view";
import { getControlCommandStatus } from "../../oversight-core/utils/get-control-command-status";
import Helper from "../../oversight-core/utils/helpers";
import {
  formatMinutesToHours,
  formatTime,
} from "../../oversight-core/utils/time-utils";
interface IProps {
  controlCommand: IShallowExportControlCommandView;
  onClick: () => void;
}

const ControlCommandCard = (props: IProps) => {
  const { controlCommand, onClick } = props;

  const ControlCommandStatus = (status: EExportControlCommandStatus) => {
    const statusComponent = useMemo(
      () => getControlCommandStatus(status),
      [status]
    );

    return <>{statusComponent}</>;
  };

  return (
    <Row
      className="align-items-center bg-white justify-content-between p-2 rounded-3 cursor-pointer gy-2 mx-0"
      onClick={onClick}
    >
      <Col className="col-12 col-lg-5 col-xl-6">
        <Row className="row-cols-auto align-items-center gap-0 gap-xl-4 gy-2">
          <Col>
            <div className="text-light font-size-12 font-weight-400">Sites</div>
            <div className="text-dark font-size-14 font-weight-600">
              {controlCommand.numberOfSites}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">
              {powerLimitTypeText[controlCommand.powerLimitType]}
            </div>
            <div className="text-dark font-size-14 font-weight-600">
              {controlCommand.powerLimitType ===
              EPowerLimitType.EXPORT_PERCENTAGE
                ? controlCommand.value
                : Number(Helper.roundTo2(controlCommand.value / 1000))}
              &nbsp;
              {controlCommand.powerLimitType ===
              EPowerLimitType.EXPORT_PERCENTAGE
                ? `%`
                : `kW`}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">
              Ramping Rate
            </div>
            <div className="text-dark font-size-14 font-weight-600">
              {formatMinutesToHours(controlCommand.rampingRateInMinutes)}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">
              AC Capacity
            </div>
            <div className="text-dark font-size-14 font-weight-600">
              {Number(Helper.roundTo2(controlCommand.totalCapacity / 1000))} kW
            </div>
          </Col>
        </Row>
      </Col>
      <Col className="col-12 col-lg-auto">
        <Row className="row-cols-auto align-items-center gap-0 gap-xl-4 gy-2">
          <Col>
            <div className="text-light font-size-12 font-weight-400">Date</div>
            <div className="text-dark font-size-12 font-weight-400">
              {moment(controlCommand.exportControlCommandStartDate).format(
                "YYYY MMM DD"
              )}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">Start</div>
            <div className="text-dark font-size-12 font-weight-400">
              {formatTime(
                new Date(controlCommand.exportControlCommandStartDate)
              )}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">
              Duration
            </div>
            <div className="text-dark font-size-12 font-weight-400">
              {formatMinutesToHours(controlCommand.duration)}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">
              {ControlCommandStatus(controlCommand.status)}
            </div>
          </Col>
          {/* <Col>
            <div className="text-light font-size-12 font-weight-400">
              Current Export
            </div>
            <div className="text-dark font-size-14 font-weight-600">
              90% | 1445 kW
            </div>
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
};

export default ControlCommandCard;
