import moment from "moment";
import { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { EExportControlCommandStatus } from "../../oversight-core/enums/export-control-command-status ";
import {
  EPowerLimitType,
  powerLimitTypeText,
} from "../../oversight-core/enums/power-limit-type";
import { AppRoute } from "../../oversight-core/interfaces/app-routes";
import { IExportControlCommandView } from "../../oversight-core/interfaces/export-control-command-view";
import AppDropDown from "../../oversight-core/ui-elements/app-dropdown/app-drop-down";
import AppButton from "../../oversight-core/ui-elements/buttons/app-button/app-button";
import { getControlCommandStatus } from "../../oversight-core/utils/get-control-command-status";
import Helper from "../../oversight-core/utils/helpers";
import {
  formatMinutesToHours,
  formatTime,
} from "../../oversight-core/utils/time-utils";
interface IProps {
  exportControlCommand: IExportControlCommandView;
  setShowStopControlCommandModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDeleteControlCommandModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const ViewControlCommandCard = (props: IProps) => {
  const {
    exportControlCommand,
    setShowStopControlCommandModal,
    setShowDeleteControlCommandModal,
  } = props;

  const navigate = useNavigate();

  const ControlCommandStatus = (status: EExportControlCommandStatus) => {
    const statusComponent = useMemo(
      () => getControlCommandStatus(status),
      [status]
    );

    return <>{statusComponent}</>;
  };

  return (
    <Row className="bg-white p-2 rounded-3 mx-0">
      <Col>
        <Row className="row-cols-auto align-items-center justify-content-between gy-2">
          <Col>
            <div className="text-light font-size-12 font-weight-400">Sites</div>
            <div className="text-dark font-size-14 font-weight-600">
              {exportControlCommand.numberOfSites}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">
              {
                powerLimitTypeText[
                  exportControlCommand.powerLimitType as EPowerLimitType
                ]
              }
            </div>
            <div className="text-dark font-size-14 font-weight-600">
              {exportControlCommand.powerLimitType ===
              EPowerLimitType.EXPORT_PERCENTAGE
                ? exportControlCommand.value
                : Number(Helper.roundTo2(exportControlCommand.value / 1000))}
              &nbsp;
              {exportControlCommand.powerLimitType ===
              EPowerLimitType.EXPORT_PERCENTAGE
                ? `%`
                : `kW`}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">
              Ramping Rate
            </div>
            <div className="text-dark font-size-14 font-weight-600">
              {formatMinutesToHours(exportControlCommand.rampingRateInMinutes)}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">
              AC Capacity
            </div>
            <div className="text-dark font-size-14 font-weight-600">
              {Number(
                Helper.roundTo2(exportControlCommand.totalCapacity / 1000)
              )}{" "}
              kW
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">Date</div>
            <div className="text-dark font-size-12 font-weight-400">
              {moment(
                exportControlCommand.exportControlCommandStartDate
              ).format("YYYY MMM DD")}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">Start</div>
            <div className="text-dark font-size-12 font-weight-400">
              {formatTime(
                new Date(exportControlCommand.exportControlCommandStartDate)
              )}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">End</div>
            <div className="text-dark font-size-12 font-weight-400">
              {formatTime(
                new Date(exportControlCommand.exportControlCommandEndDate)
              )}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">
              {ControlCommandStatus(
                exportControlCommand.status as EExportControlCommandStatus
              )}
            </div>
          </Col>
          <Col>
            <div className="text-light font-size-12 font-weight-400">
              Current Export
            </div>
            <div className="text-dark font-size-14 font-weight-600">
              {Helper.roundTo2(exportControlCommand.currentExportPercentage) ||
                0}
              %&nbsp;|&nbsp;
              {Helper.roundTo2(exportControlCommand.currentExport / 1000) || 0}
              &nbsp;kW
            </div>
          </Col>
          <Col>
            <Row className="align-items-center">
              <Col className="col-auto">
                <AppButton
                  text="Stop"
                  className="font-size-12"
                  size="medium"
                  onClick={() => setShowStopControlCommandModal(true)}
                  disabled={
                    exportControlCommand.status !==
                    EExportControlCommandStatus.IN_PROGRESS
                  }
                />
              </Col>
              {exportControlCommand.status ===
                EExportControlCommandStatus.GENERATION_COMPLETED && (
                <Col className="ps-0">
                  <AppDropDown
                    items={[
                      {
                        text: "Edit",
                        onClick: () => {
                          navigate(AppRoute.ADMIN_POWER_LIMIT_EDIT_COMMAND, {
                            state: {
                              exportControlCommand: exportControlCommand,
                            },
                          });
                        },
                      },
                      {
                        text: "Delete",
                        onClick: () => {
                          setShowDeleteControlCommandModal(true);
                        },
                      },
                    ]}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ViewControlCommandCard;
