import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useDeleteExportControlCommandMutation,
  useLazyViewExportControlCommandQuery,
  useStopExportControlCommandMutation,
} from "../../../redux/api/power-limit/powerLimitAPI";
import ViewControlCommandCard from "../../../shared/components/view-control-command-card/view-control-command-card";
import IViewExportControlCommandResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-export-control-command-response-dto";
import { EInverterStatus } from "../../../shared/oversight-core/enums/inverter-status";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { IExportControlCommandView } from "../../../shared/oversight-core/interfaces/export-control-command-view";
import { IPlantStatus } from "../../../shared/oversight-core/interfaces/plant-status";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import ArrowButton from "../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import DataTable from "../../../shared/oversight-core/ui-elements/data-table/data-table";
import RealTimeAreaChart from "../../../shared/oversight-core/ui-elements/real-time-area-chart/realtime-area-chart";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import Helper from "../../../shared/oversight-core/utils/helpers";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../shared/oversight-core/utils/toast";
import DeleteControlCommandModal from "./delete-control-command-modal/delete-control-command-modal";
import StopControlCommandModal from "./stop-control-command-modal/stop-control-command-modal";

const statusOptions: Option[] = [
  { value: EInverterStatus.SUCCESSFUL, label: "Success" },
  { value: EInverterStatus.PARTIALLY_SUCCESSFUL, label: "Partially Success" },
  { value: EInverterStatus.PENDING, label: "Pending" },
  { value: EInverterStatus.FAILED, label: "Fail" },
];

const emptyOption: Option = {
  value: "",
  label: "Status",
};

const ViewControlCommand = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [selectedStatus, setSelectedOption] = useState<Option>({
    ...emptyOption,
  });

  const [exportControlCommand, setExportControlCommand] =
    useState<IExportControlCommandView>();
  const [showStopControlCommandModal, setShowStopControlCommandModal] =
    useState(false);
  const [showDeleteControlCommandModal, setShowDeleteControlCommandModal] =
    useState(false);

  const [
    triggerExportControlCommand,
    { isFetching: isFetchingExportControlCommand },
  ] = useLazyViewExportControlCommandQuery();
  const [
    triggerStopExportControlCommand,
    { isLoading: isLoadingStopControlCommand },
  ] = useStopExportControlCommandMutation();
  const [
    triggerSDeleteExportControlCommand,
    { isLoading: isLoadingExportControlCommand },
  ] = useDeleteExportControlCommandMutation();

  useEffect(() => {
    if (state?.id) {
      triggerExportControlCommand(state?.id)
        .unwrap()
        .then((res: IViewExportControlCommandResponseDTO) => {
          setExportControlCommand(res.exportControlCommandView);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [triggerExportControlCommand, state?.id]);

  const stopExportControlCommand = (id: string) => {
    triggerStopExportControlCommand({ id })
      .unwrap()
      .then(() => {
        showSuccessMessage("Export control command stopped successfully");
        setShowStopControlCommandModal(false);
        navigate(AppRoute.ADMIN_POWER_LIMIT);
      })
      .catch(() => {
        showErrorMessage("Export control command stopped unsuccessfully");
      });
  };

  const deleteExportControlCommand = (id: string) => {
    triggerSDeleteExportControlCommand({ id })
      .unwrap()
      .then(() => {
        showSuccessMessage("Export control command deleted successfully");
        setShowDeleteControlCommandModal(false);
        navigate(AppRoute.ADMIN_POWER_LIMIT);
      })
      .catch(() => {
        showErrorMessage("Export control command deleted unsuccessfully");
      });
  };

  const graphLabels =
    exportControlCommand?.gridExports.map((i) =>
      moment(i.date).format("HH:mm")
    ) || [];
  const graphData =
    exportControlCommand?.gridExports.map((i) => i.export / 1000) || [];

  const tableData: IPlantStatus[] =
    exportControlCommand?.plantStatuses &&
    exportControlCommand?.plantStatuses?.length > 0
      ? exportControlCommand.plantStatuses.filter(
          (filterPlant) =>
            !selectedStatus.value || filterPlant.status === selectedStatus.value
        )
      : [];

  return (
    <div className="position-relative">
      <Row className="mb-2 mx-0">
        <Col>
          <ArrowButton onBack={() => navigate(-1)} />
        </Col>
      </Row>
      {exportControlCommand && (
        <Row>
          <Col>
            <ViewControlCommandCard
              exportControlCommand={exportControlCommand}
              setShowStopControlCommandModal={setShowStopControlCommandModal}
              setShowDeleteControlCommandModal={
                setShowDeleteControlCommandModal
              }
            />
          </Col>
        </Row>
      )}
      <div className="bg-white p-3 rounded-3 mt-4">
        <Row className="align-items-center justify-content-between">
          <Col className="text-dark font-size-14 font-weight-500">
            Export to grid
          </Col>
          {/* <Col className="col-auto">
            <ButtonWithBadge
              text="Filter"
              icon="filter_alt"
              onClick={() => {}}
            />
          </Col> */}
        </Row>
        <RealTimeAreaChart
          borderColor1="#0EB471"
          backgroundColor1="#29CC4D17"
          label1=""
          yAxesUnit="kW"
          labels={graphLabels}
          data1={graphData}
          isFetching={isFetchingExportControlCommand}
          allDevicesDisconnected
          isLiveData={false}
        />
      </div>
      <Row className="align-items-center justify-content-between mt-4">
        <Col className="text-dark font-size-16 font-weight-500">
          Sites Affected
        </Col>
        <Col className="col-12 col-lg-4 col-sm-6 col-xl-3 mt-2 mt-sm-0">
          <AppSelect
            selectedValue={selectedStatus}
            options={[{ value: "", label: "All" }, ...statusOptions]}
            onChangeOption={(selectedOption) => {
              setSelectedOption(selectedOption);
            }}
            className="text-light font-size-12"
          />
        </Col>
      </Row>
      <Row className="flex-column bg-white rounded-3 mt-4 mx-0">
        <Col>
          {tableData.length > 0 ? (
            <DataTable
              headers={[
                { id: 1, columnName: "Site", isSort: true, disabled: true },
                { id: 2, columnName: "Capacity" },
                { id: 2, columnName: "Export" },
                { id: 2, columnName: "Status" },
              ]}
              data={tableData?.map((plantStatus) => {
                return {
                  data: [
                    <>
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(AppRoute.ADMIN_POWER_LIMIT_VIEW_SITE, {
                            state: {
                              plantId: plantStatus.identity,
                            },
                          })
                        }
                      >
                        {plantStatus.plantName}
                      </div>
                    </>,
                    <>
                      {Number(Helper.roundTo2(plantStatus.capacity / 1000))} kW
                    </>,
                    <>
                      {Number(Helper.roundTo2(plantStatus.export / 1000))} kW
                    </>,
                    <>
                      <div
                        className={
                          plantStatus.status === EInverterStatus.FAILED
                            ? `text-red`
                            : plantStatus.status === EInverterStatus.PENDING
                            ? `text-light`
                            : `text-green-3`
                        }
                      >
                        {
                          statusOptions.find(
                            (status) => status.value === plantStatus.status
                          )?.label
                        }
                      </div>
                    </>,
                  ],
                };
              })}
            />
          ) : (
            <div className="p-3 text-center text-light font-size-14 font-weight-400">
              There are no sites
            </div>
          )}
        </Col>
      </Row>
      <DeleteControlCommandModal
        show={showDeleteControlCommandModal}
        onClose={() => setShowDeleteControlCommandModal(false)}
        onCancel={() => setShowDeleteControlCommandModal(false)}
        onConfirm={() => {
          if (state?.id) {
            deleteExportControlCommand(state?.id);
          }
        }}
        isLoading={isLoadingExportControlCommand}
      />
      <StopControlCommandModal
        show={showStopControlCommandModal}
        onClose={() => setShowStopControlCommandModal(false)}
        onCancel={() => setShowStopControlCommandModal(false)}
        onConfirm={() => {
          if (state?.id) {
            stopExportControlCommand(state?.id);
          }
        }}
        isLoading={isLoadingStopControlCommand}
      />
      <SpinnerModal show={isFetchingExportControlCommand} />
    </div>
  );
};

export default ViewControlCommand;
