import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  useLazyViewSolarPlantStatisticsAdminQuery,
  useLazyViewTotalPlantGenerationQuery,
} from "../../../redux/api/dashboard/dashboardAPI";
import SolarInfoStatisticCard from "../../../shared/components/solar-info-statistic-card/solar-info-statistic-card";
import IViewCustomerSolarPlantDetailsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-customer-solar-plant-details-response-dto";
import { IViewTotalSolarPlantEnergyResponseDTO } from "../../../shared/oversight-core/dtos/response-dtos/view-total-solar-plant-energy-response-dto";
import BarChart from "../../../shared/oversight-core/ui-elements/bar-chart/bar-chart";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import Helper from "../../../shared/oversight-core/utils/helpers";

interface ChartData {
  values: number[];
  labels: string[];
}

const defaultChartDataValues = {
  values: [],
  labels: [],
};

const AdminSolarDashboard = () => {
  const [data, setData] = useState<IViewCustomerSolarPlantDetailsResponseDTO>();
  const [solarPowerData, setSolarPowerData] = useState<ChartData>({
    ...defaultChartDataValues,
  });

  const [
    triggerSolarPlantStatistics,
    { isFetching: isFetchingSolarPlantStatistics },
  ] = useLazyViewSolarPlantStatisticsAdminQuery();
  const [
    triggerTotalPlantGeneration,
    { isFetching: isFetchingTotalPlantGeneration },
  ] = useLazyViewTotalPlantGenerationQuery();

  useEffect(() => {
    triggerSolarPlantStatistics()
      .unwrap()
      .then((res: IViewCustomerSolarPlantDetailsResponseDTO) => {
        setData(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [triggerSolarPlantStatistics]);

  useEffect(() => {
    triggerTotalPlantGeneration({
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    })
      .unwrap()
      .then((res: IViewTotalSolarPlantEnergyResponseDTO) => {
        const power = res.solarGeneration;

        setSolarPowerData({
          values: power.map((p) => p.energy / 1000),
          labels: power.map((p) => `${p.day}`),
        });
      })
      .catch(() => {
        setSolarPowerData({ ...defaultChartDataValues });
      });
  }, [triggerTotalPlantGeneration]);

  return (
    <>
      <div className="container-white">
        <Row className="gy-4">
          <Col className="col-12 col-lg-6 col-xl-3">
            <SolarInfoStatisticCard
              title="Total Sites"
              value={data?.numberOfPlant || 0}
              isLoading={isFetchingSolarPlantStatistics}
            />
          </Col>
          <Col className="col-12 col-lg-6 col-xl-3">
            <SolarInfoStatisticCard
              title="Total Generation"
              value={Number(
                Helper.roundTo2((data?.totalGeneration ?? 0) / 1000)
              )}
              unit="kWh"
              isLoading={isFetchingSolarPlantStatistics}
            />
          </Col>
          <Col className="col-12 col-lg-6 col-xl-3">
            <SolarInfoStatisticCard
              title="Monthly Total"
              value={Number(
                Helper.roundTo2((data?.totalMonthlyGeneration ?? 0) / 1000)
              )}
              unit="kWh"
              isLoading={isFetchingSolarPlantStatistics}
            />
          </Col>
          <Col className="col-12 col-lg-6 col-xl-3">
            <SolarInfoStatisticCard
              title="Today Total Generation"
              value={Number(
                Helper.roundTo2((data?.totalDailyGeneration ?? 0) / 1000)
              )}
              unit="kWh"
              isLoading={isFetchingSolarPlantStatistics}
            />
          </Col>
          <Col className="col-12 col-lg-6 col-xl-3">
            <SolarInfoStatisticCard
              title="Largest Individual Site"
              value={Number(
                Helper.roundTo2((data?.largestSiteCapacity ?? 0) / 1000)
              )}
              unit="kWh"
              isLoading={isFetchingSolarPlantStatistics}
            />
          </Col>
        </Row>
      </div>
      <div className="container-white mt-4">
        <Row className="mt-2">
          <Col>
            <div className="position-relative">
              {/* <AreaChart
                borderColor1="#D79C2C"
                backgroundColor1="#FCFDF7"
                label1="Solar Power Generation of All Sites"
                yAxesUnit="kWh"
                labels={solarPowerData.labels}
                data1={solarPowerData.values}
                isChartDataAvailable={true}
              /> */}
              <BarChart
                data1={solarPowerData.values}
                dataLabel1="Solar Power Generation of All Sites"
                labels={solarPowerData.labels}
                borderColor1="#D79C2C"
                backgroundColor1="#FCFDF7"
                yAxesUnit="kWh"
                isDisplayLegend
                isChartDataAvailable={solarPowerData.values.length > 0}
              />
              <SpinnerModal show={isFetchingTotalPlantGeneration} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AdminSolarDashboard;
