export enum EPowerLimitType {
  MAXIMUM_EXPORT = "MAXIMUM_EXPORT",
  RELATIVE_MAXIMUM_EXPORT = "RELATIVE_MAXIMUM_EXPORT",
  POWER_CURTAIL = "POWER_CURTAIL",
  EXPORT_PERCENTAGE = "EXPORT_PERCENTAGE",
}

export const powerLimitTypeText = {
  [EPowerLimitType.MAXIMUM_EXPORT]: "Maximum Export",
  [EPowerLimitType.RELATIVE_MAXIMUM_EXPORT]: "Relative Maximum Export",
  [EPowerLimitType.POWER_CURTAIL]: "Curtailment",
  [EPowerLimitType.EXPORT_PERCENTAGE]: "Limit Export to",
};
