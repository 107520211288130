import IPowerLimitPlantBlackoutRequestDTO from "../../../shared/oversight-core/dtos/request-dtos/power-limit-plant-blackout-request-dto";
import { rootApi } from "../apiManager";

export const blackoutAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    powerLimitPlantsBlackout: builder.mutation<
      void,
      IPowerLimitPlantBlackoutRequestDTO
    >({
      query(data) {
        return {
          url: "admin/power-limit/blackout",
          method: "POST",
          body: data,
        };
      },
    }),
    powerLimitPlantsShutdownAll: builder.mutation<
      void,
      { isAllShutdown: boolean }
    >({
      query({ isAllShutdown }) {
        return {
          url: "admin/power-limit/blackout/shutdown-all",
          method: "POST",
          params: { isAllShutdown },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  usePowerLimitPlantsBlackoutMutation,
  usePowerLimitPlantsShutdownAllMutation,
} = blackoutAPI;
