import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import {
  useLazyViewGenerationServiceProvidersQuery,
  useLazyViewUserPlantDailyPowerQuery,
  useLazyViewUserPlantDetailsQuery,
  useLazyViewUserPlantGenerationQuery,
  useLazyViewUserPlantInvertersQuery,
} from "../../../redux/api/generation/generationAPI";
import {
  selectSolarFilter,
  setSolarFilter,
} from "../../../redux/features/solar-filter/solar-filter-slice";
import deyeLogo from "../../../shared/oversight-core/assets/images/deye-logo.png";
import goodWeLogo from "../../../shared/oversight-core/assets/images/goodwe-logo.svg";
import IViewAllGenerationServiceProvidersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-all-generation-service-providers-response-dto";
import IViewUserSolarPlantDetailsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-details-response-dto";
import IViewUserSolarPlantEnergyResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-energy-response-dto";
import IViewUserSolarPlantPowerResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-power-response-dto";
import IViewUserSolarPlantInvertersResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-user-solar-plantInverters-response-dto";
import { EDateTypes } from "../../../shared/oversight-core/enums/date-types";
import { EGraphFilters } from "../../../shared/oversight-core/enums/graph-filters";
import { EPlantStatus } from "../../../shared/oversight-core/enums/plant-status";
import { ESolarServiceProvider } from "../../../shared/oversight-core/enums/solar-service-provider";
import { EUnitType } from "../../../shared/oversight-core/enums/unit-type";
import { IBatteryInverterView } from "../../../shared/oversight-core/interfaces/battery-inverter-view";
import { IGenerationServiceProviderView } from "../../../shared/oversight-core/interfaces/generation-service-provider-view";
import { IExtendGenericSolarPlantInverters } from "../../../shared/oversight-core/interfaces/generic-page-solar-plant-inverter-view";
import { IGridInverterView } from "../../../shared/oversight-core/interfaces/grid-inverter-view";
import { ISolarPlantView } from "../../../shared/oversight-core/interfaces/solar-plant-view";
import BatteryInfoCard from "../../../shared/oversight-core/shared-components/battery-info-card/battery-info-card";
import GaugeInfoCard from "../../../shared/oversight-core/shared-components/gauge-info-card/gauge-info-card";
import DeyeInverterInfoCard from "../../../shared/oversight-core/shared-components/inverter-info-card/deye-inverter-info-card";
import InverterInfoCard from "../../../shared/oversight-core/shared-components/inverter-info-card/inverter-info-card";
import SolarInfoCard from "../../../shared/oversight-core/shared-components/solar-info-card/solar-info-card";
import WetherInfo from "../../../shared/oversight-core/shared-components/wether-info/wether-info";
import ActiveInactiveIndicator from "../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import ArrowButton from "../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import ButtonWithBadge from "../../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import EnergySourceInfo from "../../../shared/oversight-core/ui-elements/energy-source-info/energy-source-info";
import Pagination from "../../../shared/oversight-core/ui-elements/pagination/pagination";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import Helper from "../../../shared/oversight-core/utils/helpers";
import SolarFilterSidePanel from "../../solar/solar-filter-side-panel/solar-filter-side-panel";
import styles from "./solar-information.module.scss";

interface ChartData {
  values: number[];
  labels: string[];
}

const defaultChartDataValues = {
  values: [],
  labels: [],
};

const unitTypes: Option[] = [
  {
    value: EGraphFilters.POWER,
    label: "Power(kW)",
  },
  {
    value: EGraphFilters.ENERGY,
    label: "Energy(kWh)",
  },
  {
    value: EGraphFilters.BATTERY_CHARGED,
    label: "Battery Charged",
  },
  {
    value: EGraphFilters.BATTERY_LEVEL,
    label: "Battery Level",
  },
];

interface IProps {
  plantId?: string;
}

const SolarInformation = (props: IProps) => {
  const { state } = useLocation();
  const plantId = state?.plantId || props.plantId;

  const solarFilterStore = useSelector(selectSolarFilter);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedUnitType, setSelectedUnitType] = useState<Option>({
    ...unitTypes[0],
  });
  const [powerChartData, setPowerChartData] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [energyChartData, setEnergyChartData] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [batteryPowerData, setBatteryPowerData] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [batteryLevel, setBatteryLevel] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [solarPlant, setSolarPlant] = useState<ISolarPlantView>();
  const [gridInverters, setGridInverters] = useState<IGridInverterView[]>([]);
  const [batteryInverters, setBatteryInverters] = useState<
    IBatteryInverterView[]
  >([]);
  const [deyeInverters, setDeyeInverters] = useState<
    IExtendGenericSolarPlantInverters[]
  >([]);
  const [showSolarFilterSidePanel, setShowSolarFilterSidePanel] =
    useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [filter, setFilter] = useState({ pageNumber: 0 });
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState<
    IGenerationServiceProviderView | undefined
  >(undefined);

  const [triggerUserPlantDetails, { isFetching: isFetchingPlantDetails }] =
    useLazyViewUserPlantDetailsQuery();
  const [triggerUSerPlantInverters, { isFetching: isFetchingPlantInverters }] =
    useLazyViewUserPlantInvertersQuery();
  const [triggerDailyPlantPower, { isFetching: isFetchingDailyPlantPower }] =
    useLazyViewUserPlantDailyPowerQuery();
  const [triggerDailyPlantEnergy, { isFetching: isFetchingDailyPlantEnergy }] =
    useLazyViewUserPlantGenerationQuery();
  const [
    triggerGenerationServiceProviders,
    { isFetching: isFetchingGenerationServiceProviders },
  ] = useLazyViewGenerationServiceProvidersQuery();

  useEffect(() => {
    triggerGenerationServiceProviders()
      .unwrap()
      .then((res: IViewAllGenerationServiceProvidersResponseDTO) => {
        setSelectedServiceProvider(
          res.generationServiceProviders.find(
            (sp) => sp.identity === solarPlant?.generationServiceProviderId
          )
        );
      })
      .catch(() => {
        setSelectedServiceProvider(undefined);
      });
  }, [triggerGenerationServiceProviders, solarPlant]);

  useEffect(() => {
    if (plantId) {
      triggerUserPlantDetails({ plantId: plantId })
        .unwrap()
        .then((res: IViewUserSolarPlantDetailsResponseDTO) => {
          setSolarPlant(res.solarPlantView);
          setIsFirstTimeLoading(false);
        })
        .catch(() => {
          console.log("Failed to get plant details");
          setIsFirstTimeLoading(false);
        });
    }
  }, [triggerUserPlantDetails, plantId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (plantId) {
        triggerUserPlantDetails({ plantId: plantId })
          .unwrap()
          .then((res: IViewUserSolarPlantDetailsResponseDTO) => {
            setSolarPlant(res.solarPlantView);
          })
          .catch(() => {
            console.log("Failed to get plant details");
          });
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [triggerUserPlantDetails, plantId]);

  useEffect(() => {
    if (
      selectedUnitType.value === EGraphFilters.POWER &&
      solarFilterStore &&
      plantId
    ) {
      triggerDailyPlantPower({
        plantId: plantId,
        year: new Date(solarFilterStore.selectedDate).getFullYear(),
        month: new Date(solarFilterStore.selectedDate).getMonth() + 1,
        day: new Date(solarFilterStore.selectedDate).getDate(),
      })
        .unwrap()
        .then((res: IViewUserSolarPlantPowerResponseDTO) => {
          const power = res.solarPlantPowerView.solarPower;
          const batteryPower = res.solarPlantPowerView.batterPower;
          const batteryLevel = res.solarPlantPowerView.batterLevel;
          setPowerChartData({
            values: power.map((p) => p.power / 1000),
            labels: power.map((p) => moment(p.date).utc().format("HH:mm")),
          });
          setBatteryPowerData({
            values: batteryPower.map((p) => p.power / 1000),
            labels: batteryPower.map((p) =>
              moment(p.date).utc().format("HH:mm")
            ),
          });
          setBatteryLevel({
            values: batteryLevel.map((b) => b.level),
            labels: batteryLevel.map((b) =>
              moment(b.date).utc().format("HH:mm")
            ),
          });
        })
        .catch(() => {
          setPowerChartData({ ...defaultChartDataValues });
          setBatteryPowerData({ ...defaultChartDataValues });
          setBatteryLevel({ ...defaultChartDataValues });
        });
    }
  }, [
    triggerDailyPlantPower,
    solarFilterStore,
    selectedUnitType.value,
    plantId,
  ]);

  useEffect(() => {
    if (
      selectedUnitType.value === EGraphFilters.ENERGY &&
      solarFilterStore &&
      plantId
    ) {
      triggerDailyPlantEnergy({
        plantId: plantId,
        year: new Date(solarFilterStore.selectedDate).getFullYear(),
        month: new Date(solarFilterStore.selectedDate).getMonth() + 1,
        day: new Date(solarFilterStore.selectedDate).getDate(),
        viewType: solarFilterStore.selectedDateType,
      })
        .unwrap()
        .then((res: IViewUserSolarPlantEnergyResponseDTO) => {
          const energy = res.solarPlantEnergyView.solarEnergy;
          setEnergyChartData({
            values: energy.map((e) => e.power / 1000),
            labels: energy.map((e) =>
              moment(e.date)
                .utcOffset("+05:30")
                .format(
                  solarFilterStore.selectedDateType === EDateTypes.DAILY
                    ? "MM-DD"
                    : solarFilterStore.selectedDateType === EDateTypes.MONTHLY
                    ? "MM-YYYY"
                    : "YYYY"
                )
            ),
          });
        })
        .catch(() => {
          setEnergyChartData({ ...defaultChartDataValues });
        });
    }
  }, [
    triggerDailyPlantEnergy,
    solarFilterStore,
    selectedUnitType.value,
    plantId,
  ]);

  useEffect(() => {
    if (plantId) {
      triggerUSerPlantInverters({
        plantId: plantId,
        pageNumber: filter.pageNumber,
        pageSize: 4,
      })
        .unwrap()
        .then((res: IViewUserSolarPlantInvertersResponseDTO) => {
          setGridInverters(res.genericPage.elements[0].gridInverterViews);
          setBatteryInverters(res.genericPage.elements[0].batteryInverterViews);
          setDeyeInverters(res.genericPage.elements);
          setTotalElements(res.genericPage.totalElements);
        })
        .catch(() => {
          setTotalElements(0);
        });
    }
  }, [triggerUSerPlantInverters, filter.pageNumber, plantId]);

  const onFilter = (selectedDate: Date, selectedDateType: EDateTypes) => {
    dispatch(
      setSolarFilter({
        selectedDate: moment(selectedDate).valueOf(),
        selectedDateType,
      })
    );
    setShowSolarFilterSidePanel(false);
  };

  const onClearAll = () => {
    dispatch(
      setSolarFilter({
        selectedDate: moment().valueOf(),
        selectedDateType: EDateTypes.DAILY,
      })
    );
    setShowSolarFilterSidePanel(false);
  };

  const modifiedEnergyChartValues = [...energyChartData.values];
  if (modifiedEnergyChartValues.length > 0) {
    modifiedEnergyChartValues[modifiedEnergyChartValues.length - 1] +=
      solarPlant?.dailyGeneration ?? 0;
  }

  return (
    <>
      <Row className="align-items-center">
        <Col className="col-12 col-sm-auto mx-2">
          <ArrowButton onBack={() => navigate(-1)} />
        </Col>
        <Col className="mt-2 mt-sm-0">
          <div className="container-white">
            <Row className="align-items-center justify-content-between gy-3">
              <Col className="col-auto">
                <Row className="align-items-center gy-3">
                  <Col className="col-auto">
                    <Row>
                      <Col className="text-dark font-weight-500 font-size-14">
                        {solarPlant?.plantName}
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col
                        className={`col-auto text-light font-weight-400 font-size-12 pe-0 ${styles.textTruncate}`}
                        id={`location-${solarPlant?.plantId}`}
                      >
                        {solarPlant?.address}
                      </Col>
                      <Col className="ps-2">
                        <ActiveInactiveIndicator
                          isActive={
                            solarPlant?.status === EPlantStatus.GENERATING
                          }
                        />
                      </Col>
                      <Tooltip
                        offset={5}
                        anchorSelect={`#location-${solarPlant?.plantId}`}
                        content={solarPlant?.address}
                        className={styles.toolTip}
                        classNameArrow={styles.toolTipArrow}
                      />
                    </Row>
                  </Col>
                  <Col>
                    {!isFetchingGenerationServiceProviders &&
                      !isFirstTimeLoading && (
                        <img
                          src={
                            selectedServiceProvider &&
                            selectedServiceProvider?.shortName ===
                              ESolarServiceProvider.GOODWE
                              ? goodWeLogo
                              : deyeLogo
                          }
                          width={120}
                          alt=""
                        />
                      )}
                  </Col>
                </Row>
              </Col>
              <Col className="col-auto">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <Row>
                      <Col className="text-dark font-weight-500 font-size-14 mb-2">
                        Total Generation
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <EnergySourceInfo
                          title="Solar"
                          value={Number(
                            Helper.roundTo2(solarPlant?.totalGeneration ?? 0)
                          )}
                          icon="solar_power"
                          isFetching={false}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="mt-1 g-4">
        <Col
          className={`col-12  ${
            solarPlant?.isStored ? `col-lg-6 col-xxl-3` : `col-lg-4`
          }`}
        >
          <GaugeInfoCard
            value={solarPlant?.pvPower ?? 0}
            capacity={solarPlant?.pvCapacity ?? 0}
            circleRation={1}
            title="Current Generation"
            unitType="kW"
          />
        </Col>
        {solarPlant?.isStored && (
          <Col className="col-12 col-lg-6 col-xxl-auto">
            <BatteryInfoCard
              value={solarPlant?.batterySoc ?? 0}
              batteryCapacity={solarPlant?.batteryCapacity}
            />
          </Col>
        )}
        <Col className="col-12 col-sm-6 col-lg-4 col-xxl">
          <SolarInfoCard
            title="Today Generation"
            value={`${solarPlant?.dailyGeneration ?? 0} kWh`}
            icon="solar_power"
            iconColor="#D08700"
            backgroundColor="#F7F8EE"
            isFetching={isFetchingPlantDetails && isFirstTimeLoading}
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-4 col-xxl">
          <SolarInfoCard
            title="Today Income"
            value={`${solarPlant?.dailyIncome ?? 0} ${
              solarPlant?.currency ?? ""
            }`}
            icon="payments"
            iconColor="#8EBB13"
            backgroundColor="#F5F8EE"
            isFetching={isFetchingPlantDetails && isFirstTimeLoading}
          />
        </Col>
        {/* <Col className="col-12 col-sm-6 col-lg-6 col-xxl">
          <SolarInfoCard
            title="Total Generation"
            value={`${solarPlant?.totalGeneration ?? 0} kWh`}
            icon="solar_power"
            iconColor="#B867B6"
            backgroundColor="#F8F6F8"
            isFetching={isFetchingPlantDetails && isFirstTimeLoading}
          />
        </Col> */}
        <Col className="col-12 col-sm-6 col-lg-4 col-xxl">
          <SolarInfoCard
            title="Total Income"
            value={`${solarPlant?.totalIncome ?? 0} ${
              solarPlant?.currency ?? ""
            }`}
            icon="payments"
            iconColor="#26A767"
            backgroundColor="#F0FAF6"
            isFetching={isFetchingPlantDetails && isFirstTimeLoading}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="col-12 col-xl-9">
          <div className="container-white position-relative">
            <Row className="align-items-center justify-content-end">
              <Col className="col-auto col-sm-5 col-lg-4 col-xl-3">
                <AppSelect
                  className="mt-1"
                  selectedValue={{ ...selectedUnitType }}
                  options={[...unitTypes]}
                  onChangeOption={(selectedOption) => {
                    setSelectedUnitType(selectedOption);
                  }}
                  id="unit-type"
                />
              </Col>
              <Col className="col-auto ps-1">
                <ButtonWithBadge
                  text="Filter"
                  icon="filter_alt"
                  onClick={() => {
                    setShowSolarFilterSidePanel(true);
                  }}
                  badgeValue={0}
                />
              </Col>
            </Row>
            {selectedUnitType.value === EGraphFilters.BATTERY_CHARGED ? (
              <AreaChart
                borderColor1="#5030B0"
                backgroundColor1="#4C29CC17"
                label1="Battery Charged"
                yAxesUnit="kW"
                labels={batteryPowerData.labels}
                data1={batteryPowerData.values}
                isChartDataAvailable={batteryPowerData.values.length > 0}
              />
            ) : selectedUnitType.value === EGraphFilters.BATTERY_LEVEL ? (
              <AreaChart
                borderColor1="#5030B0"
                backgroundColor1="#4C29CC17"
                label1="Battery Level"
                yAxesUnit="%"
                labels={batteryLevel.labels}
                data1={batteryLevel.values}
                isChartDataAvailable={batteryLevel.values.length > 0}
              />
            ) : (
              <AreaChart
                borderColor1="#2DEC9F"
                backgroundColor1="#29CC3917"
                label1={
                  selectedUnitType.value === EGraphFilters.POWER
                    ? "Solar Power"
                    : "Solar Generation"
                }
                yAxesUnit={
                  selectedUnitType.value === EGraphFilters.POWER ? `kW` : `kWh`
                }
                labels={
                  selectedUnitType.value === EGraphFilters.POWER
                    ? powerChartData.labels
                    : energyChartData.labels
                }
                data1={
                  selectedUnitType.value === EGraphFilters.POWER
                    ? powerChartData.values
                    : modifiedEnergyChartValues
                }
                isChartDataAvailable={
                  selectedUnitType.value === EGraphFilters.POWER
                    ? powerChartData.values.length > 0
                    : modifiedEnergyChartValues.length > 0
                }
              />
            )}
            <SpinnerModal
              show={isFetchingDailyPlantPower || isFetchingDailyPlantEnergy}
            />
          </div>
        </Col>
        <Col className="col-12 col-xl-3 mt-4 mt-xl-0">
          <WetherInfo
            longitude={solarPlant?.longitude ?? 0}
            latitude={solarPlant?.latitude ?? 0}
            address={solarPlant?.address ?? ""}
            isFetching={isFetchingPlantDetails && isFirstTimeLoading}
          />
        </Col>
      </Row>
      <Row className="align-items-center mt-5">
        <Col className="text-dark font-weight-600 font-size-24">Inverters</Col>
      </Row>
      {isFetchingPlantInverters ? (
        <Row>
          <Col>
            <SpinnerModal show={isFetchingPlantInverters} withOverlay={false} />
          </Col>
        </Row>
      ) : (
        <>
          {selectedServiceProvider &&
          selectedServiceProvider?.shortName === ESolarServiceProvider.DEYE &&
          deyeInverters &&
          deyeInverters.length > 0 ? (
            <div className="mt-4 position-relative">
              {deyeInverters.map((d) => {
                return (
                  <Row key={d.plantId} className="mt-2">
                    <Col>
                      <DeyeInverterInfoCard inverter={d} />
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : gridInverters && gridInverters.length > 0 ? (
            <div className="mt-4 position-relative">
              {gridInverters.map((s) => {
                return (
                  <Row key={s.plantId} className="mt-2">
                    <Col>
                      <InverterInfoCard gridInverter={s} />
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : batteryInverters && batteryInverters.length > 0 ? (
            <div className="mt-4 position-relative">
              {batteryInverters.map((s, index) => {
                return (
                  <Row key={index} className="mt-2">
                    <Col>
                      <InverterInfoCard batteryInverter={s} />
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : (
            <>
              {!isFetchingPlantInverters && (
                <div className="mt-4 container-dash text-center text-light font-weight-400 font-size-14">
                  There are no inverters
                </div>
              )}
            </>
          )}
        </>
      )}
      {(gridInverters && gridInverters.length > 4) ||
        (batteryInverters && batteryInverters.length > 4) ||
        (deyeInverters && deyeInverters.length > 4 && (
          <Pagination
            itemsPerPage={4}
            length={totalElements}
            currentPage={filter.pageNumber + 1}
            updateCurrentPage={(pn) => {
              setFilter((ps) => ({ ...ps, pageNumber: pn - 1 }));
            }}
          />
        ))}
      <SolarFilterSidePanel
        showFilter={showSolarFilterSidePanel}
        setShowFilter={(value: boolean) => {
          setShowSolarFilterSidePanel(value);
        }}
        onFilter={onFilter}
        onClearAll={onClearAll}
        selectedUnitType={selectedUnitType.value as EUnitType}
      />
      <SpinnerModal show={isFetchingPlantDetails && isFirstTimeLoading} />
    </>
  );
};

export default SolarInformation;
