import ModalContainer, {
  ModelContainerProps,
} from "../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
}

const DeleteControlCommandModal = (props: IProps) => {
  const { show, ...rest } = props;

  return (
    <ModalContainer
      {...rest}
      title="Delete Control Command"
      show={show}
      size="modal-md"
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
      confirmButtonVariant="red"
    >
      <>
        <p className="text-light font-size-14 font-weight-400">
          Are you sure you want to delete this control command ?
        </p>
      </>
    </ModalContainer>
  );
};

export default DeleteControlCommandModal;
