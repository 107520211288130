import { Col, Row } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { EPlantStatus } from "../../oversight-core/enums/plant-status";
import { IShallowSolarPlantView } from "../../oversight-core/interfaces/shallow-solar-plant-view";
import ActiveInactiveIndicator from "../../oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppButton from "../../oversight-core/ui-elements/buttons/app-button/app-button";
import SiteStats from "../../oversight-core/ui-elements/site-stats/site-stats";
import styles from "./site-card.module.scss";

interface IProps {
  solarPlant: IShallowSolarPlantView;
  onSelect?: () => void;
  onClick: () => void;
  selectedSolarPlant?: IShallowSolarPlantView;
  selectedTabId: number;
}

const SiteCard = (props: IProps) => {
  const { solarPlant, onClick, onSelect, selectedSolarPlant, selectedTabId } =
    props;
  return (
    <Row
      className={`${
        selectedTabId === 2 && `cursor-pointer`
      } align-items-center justify-content-between p-3 gx-0 h-100 ${
        styles.card
      } ${
        solarPlant.plantId ===
          (selectedSolarPlant && selectedSolarPlant.plantId) &&
        selectedTabId !== 1 &&
        styles.selectedCardColor
      }`}
      onClick={onSelect}
    >
      <Col className="col-6 col-sm-auto">
        <Row>
          <Col
            id={`location-${solarPlant.plantId}-1`}
            className={`${styles.textOne} ${styles.textOneTruncate}`}
          >
            {solarPlant.plantName}
          </Col>
          <Tooltip
            offset={5}
            anchorSelect={`#location-${solarPlant.plantId}-1`}
            content={solarPlant.plantName}
            className={styles.toolTip}
            classNameArrow={styles.toolTipArrow}
          />
        </Row>
        <Row className="align-items-center">
          <Col
            id={`location-${solarPlant.plantId}-2`}
            className={`${styles.textTow} ${styles.textTruncate} col-auto pe-0`}
          >
            {solarPlant.address}
          </Col>
          <Col className="ps-2">
            <ActiveInactiveIndicator
              isActive={solarPlant.status === EPlantStatus.GENERATING}
            />
          </Col>
          <Tooltip
            offset={5}
            anchorSelect={`#location-${solarPlant.plantId}-2`}
            content={solarPlant.address}
            className={styles.toolTip}
            classNameArrow={styles.toolTipArrow}
          />
        </Row>
      </Col>
      <Col className="col-auto">
        <Row className="align-items-center">
          <Col className="col-auto">
            <SiteStats value={solarPlant.capacity} />
          </Col>
          <Col className="ps-0">
            <AppButton
              text="visibility"
              iconName="visibility"
              iconOnly
              type="button"
              iconSize={18}
              size="extra-small"
              onClick={onClick}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SiteCard;
