import { Col, Row } from "react-bootstrap";
import { IExtendGenericSolarPlantInverters } from "../../interfaces/generic-page-solar-plant-inverter-view";
import Gauge from "../../ui-elements/gauge/gauge";
import Helper from "../../utils/helpers";
import styles from "./inverter-info-card.module.scss";
import InverterInformation from "./inverter-information/inverter-information";

interface IProps {
  inverter?: IExtendGenericSolarPlantInverters;
}

const DeyeInverterInfoCard = (props: IProps) => {
  const { inverter } = props;

  return (
    <>
      <div className={`${styles.infoCardContainer} p-4`}>
        <Row className="align-items-center">
          <Col className="text-dark font-weight-500 font-size-24">
            {inverter?.inverterType}
          </Col>
        </Row>
        <Row className="align-items-center justify-content-between my-1 g-4">
          <Col className="col-12 col-lg-auto">
            <Row className="justify-content-center justify-content-lg-start">
              <Col className="col-auto">
                <Gauge
                  icon="solar_power"
                  value={Number(inverter?.dailyProductionActive) / 1000}
                  maxValue={0}
                  circleRatio={1}
                  unit={"kWh"}
                  trailColor="#D08700"
                  width={150}
                  height={75}
                  isDifferentStyles
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-5 pt-5">
              <Col className="col-auto text-light font-weight-500 font-size-16">
                Today Generation
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-sm-6 col-lg-auto">
            <InverterInformation
              inverterInfo={[
                {
                  title: "Inverter Type",
                  value: inverter?.inverterType,
                },
                {
                  title: "SN",
                  value: inverter?.sn,
                },
                {
                  title: "Daily Consumption",
                  value: `${Number(inverter?.dailyConsumption) / 1000} kWh`,
                },
                {
                  title: "Rated Power",
                  value: `${Number(inverter?.ratedPower) / 1000} kW`,
                },
                {
                  title: "Battery Voltage",
                  value: inverter?.batteryVoltageType,
                },
              ]}
            />
          </Col>
          <Col className="col-12 col-sm-6 col-lg-auto">
            <InverterInformation
              inverterInfo={[
                {
                  title: "BMS Temp",
                  value: <>{inverter?.bMSTemperature} C&deg;</>,
                },
                {
                  title: "Power",
                  value: `${Helper.roundTo2(
                    Number(inverter?.totalDCInputPower) / 1000
                  )} kW`,
                },
                {
                  title: "AC Current",
                  value: `${inverter?.aCCurrent} A`,
                },
                {
                  title: "AC Voltage",
                  value: `${inverter?.aCVoltage} V`,
                },
                {
                  title: "AC Frequency",
                  value: `${inverter?.acOutputFrequencyR} Hz`,
                },
              ]}
            />
          </Col>
          <Col className="col-12 col-xxl-auto">
            <Row className="g-4">
              <Col className={` col-12 col-sm-6 col-xxl-12`}>
                <div className={styles.background1}>
                  <div className="text-light font-weight-500 font-size-16">
                    Total Generation
                  </div>
                  <div className="text-dark font-weight-500 font-size-16 mt-2">
                    {Helper.roundTo2(
                      Number(inverter?.cumulativeProductionActive) / 1000
                    )}
                    &nbsp; kWh
                  </div>
                </div>
              </Col>
              <Col className={` col-12 col-sm-6 col-xxl-12`}>
                <div className={styles.background1}>
                  <div className="text-light font-weight-500 font-size-16">
                    Daily Export
                  </div>
                  <div className="text-dark font-weight-500 font-size-16 mt-2">
                    {Number(inverter?.dailyGridFeedIn) / 1000}&nbsp;kWh
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DeyeInverterInfoCard;
