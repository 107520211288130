import { Table } from "react-bootstrap";
import MaterialIcon from "../material-icon/material-icon";
import TableRow, { ITableRowProps } from "./table-row/table-row";

interface IHeader {
  id: number;
  columnName: string | JSX.Element;
  isSort?: boolean;
  sortFunction?: () => void;
  sort?: boolean;
  disabled?: boolean;
}

interface IProps {
  headers: IHeader[];
  data: ITableRowProps[];
}

const DataTable = (props: IProps) => {
  const { headers, data } = props;
  return (
    <Table hover responsive className="mt-4">
      <thead>
        <tr>
          {headers.map((header) => {
            return (
              <th
                key={header.id}
                className="text-dark font-size-14 font-weight-400"
                style={{ verticalAlign: "middle" }}
              >
                {header.isSort ? (
                  <div
                    className={`d-flex align-items-center gap-1 ${
                      header.disabled ? `user-select-none` : `cursor-pointer`
                    }`}
                    onClick={header.sortFunction && header.sortFunction}
                  >
                    {header.columnName}
                    <div
                      className="dArrow"
                      style={
                        !header.sort
                          ? { transform: "rotate(0deg)" }
                          : { transform: "rotate(180deg)" }
                      }
                    >
                      <MaterialIcon
                        icon="arrow_downward"
                        color="#383941"
                        size={14}
                      />
                    </div>
                  </div>
                ) : (
                  header.columnName
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data && data.map((item, index) => <TableRow key={index} {...item} />)}
      </tbody>
    </Table>
  );
};

export default DataTable;
